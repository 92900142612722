import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsHelper } from '../utils.helper';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {

  constructor(private _router: Router) { }

  canActivate(): boolean {
    if (UtilsHelper.IsLoggedIn) {
      this._router.navigate(['dashboard/chats']);
      return false;
    }
    return true;
  }

  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (UtilsHelper.IsLoggedIn) {
        this._router.navigate(['dashboard/chats']);
        return false;
      }
      return true;
    }

}

