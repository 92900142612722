import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ChangePasswordModal, LoginModal } from '@models/auth';
import { BehaviorSubject } from "rxjs";
import { CommonService } from './common.service';

let headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  organizationId$ = new BehaviorSubject<string>("");

  constructor(private httpClient: HttpClient, private commonService: CommonService) { }

  loginUserPost(data: LoginModal, token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.post(this.commonService.envUrl() + 'auth/login', data, { headers: headers });
  }

  fetchAppToken() {
    // TODO: FIND A BETTER TO DOING THIS
    let headers = new HttpHeaders({ 'appId': 'ha23Mc2vk2', 'type': 'Truventor One', 'clientId': '637926389712', 'clientSecret': 'TBc09nk9ilmtT3' });
    return this.httpClient.get(this.commonService.envUrl() + 'auth/generate.auth.code', { headers: headers });
  }

  reisterNewUserPost(data, token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.post(this.commonService.envUrl() + 'auth/register', data, { headers: headers });
  }

  reisterAuthToken(token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.get(this.commonService.engageUrl() + 'user/validate.register.token', { headers: headers });
  }

  forgotPasswordPost(email) {
    return this.httpClient.post(this.commonService.envUrl() + 'auth/forgot.password', email);
  }

  resetPasswordPost(data, token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.post(this.commonService.envUrl() + 'auth/reset.password', data, { headers: headers });
  }

  changePasswordPost(data: ChangePasswordModal) {
    return this.httpClient.post(this.commonService.envUrl() + `password/change`, data);
  }

  fetchUserProfile() {
    return this.httpClient.get(this.commonService.envUrl() + 'user/profile');
  }

  updateUserProfile(data) {
    return this.httpClient.put(this.commonService.envUrl() + 'user/profile', data);
  }

  refreshToken() {
    return this.httpClient.get(this.commonService.envUrl() + 'token/refresh');
  }

  verifyRegistration(token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.get(this.commonService.envUrl() + 'auth/email.verify', { headers: headers });
  }

  getAllApp(token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.get(this.commonService.envUrl() + 'app', { headers: headers });
  }

  logoutAPI() {
    return this.httpClient.get(this.commonService.envUrl() + 'user/logout');
  }

  //Org
  getOrganizations(size, page) {
    return this.httpClient.get(this.commonService.envUrl() + 'admin/get.orgs?size=' + size + '&page=' + page);
  }

  //Users
  getUsers(size, page) {
    let org_id;
    this.organizationId$.subscribe(res => {
      if (res)
        org_id = res;
    });
    if (org_id)
      return this.httpClient.get(this.commonService.envUrl() + 'admin/org.users?size=' + size + '&page=' + page + '&orgId=' + org_id);

    return this.httpClient.get(this.commonService.envUrl() + 'admin/users?size=' + size + '&page=' + page);
  }

  removeUser(email) {
    return this.httpClient.delete(this.commonService.envUrl() + 'admin/user?email=' + email);
  }

  // Roles
  getGeneralRoles() {
    return this.httpClient.get(this.commonService.envUrl() + 'role');
  }

  getRoles() {
    return this.httpClient.get(this.commonService.envUrl() + 'admin/roles');
  }

  updateRole(body) {
    return this.httpClient.put(this.commonService.envUrl() + 'admin/update.role', body);
  }

  removeRole(email, role_id) {
    return this.httpClient.delete(this.commonService.envUrl() + 'admin/remove.role?email=' + email + '&role_id=' + role_id);
  }

  //Country Codes
  getCountryCode() {
    return this.httpClient.get('./assets/jsonData/country_phone_code.json')
  }
}