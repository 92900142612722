import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from '@models/auth';
import { AuthService } from '@services/auth.service';
import { CommonService } from '@services/common.service';
import { UploadFilesService } from '@services/upload-files.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscriber, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastDisplay } from 'src/app/shared/toastr/toast-service';
import { UtilsHelper } from 'src/app/shared/utils.helper';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  public userProfileData = new UserProfile();
  public formSubmitted: boolean = false;
  public showImageMenu: boolean = false;
  public editProfile: boolean = false;
  public fileToUpload: any;
  public tempPhoto: string;
  public tempName: string;
  private subscribeURL = new Subscription();

  private unsubscribe$ = new Subject<void>();
  @Output() closeMyProfile = new EventEmitter();
  constructor(
    private loader: NgxUiLoaderService,
    private userService: AuthService,
    private toastr: ToastDisplay,
    private uploadFileService: UploadFilesService,
    private commonService: CommonService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.loader.stop();
    this.getUserProfileData();
  }

  hideMyProfile = () => {
    this.closeMyProfile.emit();
  }

  getUserProfileData() {
    this.loader.start();
    this.userService
      .fetchUserProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res['status'] == 'success') {
            this.userProfileData = res['data'][0];
            if (this.userProfileData.photo) {
              const fileName = this.userProfileData.photo.substr(
                this.userProfileData.photo.indexOf('.com/') + 5
              );
              this.userProfileData.photo =
                this.uploadFileService.getPreSignedURL(fileName);
                this.tempPhoto = this.userProfileData.photo;
                this.tempName = this.userProfileData.name;
            }
          }
          this.loader.stop();
        },
        (err) => {
          this.loader.stop();
        }
      );
  }

  matchPattern(): boolean {
    const pattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
    return !!this.userProfileData.email?.match(pattern);
  }

  onlyCharacters(str): boolean {
    const pattern = /^[A-Za-z \ \-]+$/;
    return !!str?.match(pattern);
  }

  updateProfile(myForm) {
    this.formSubmitted = true;
    if (
      myForm.valid &&
      this.matchPattern() &&
      this.onlyCharacters(this.userProfileData.name)
    ) {
      this.loader.start();
      this.userService
        .updateUserProfile({
          name: this.userProfileData.name,
          orgName: this.userProfileData.orgName,
          photo: this.userProfileData.photo.split('?')[0],
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res) => {
            if (res['status'] == 'success') {
              this.toastr.showSuccess(res['message']);
              this.commonService.profileImageUrl$.next(this.userProfileData.photo);
              this.commonService.profileName$.next(this.userProfileData.name);
              UtilsHelper.setObject('userName', this.userProfileData.name);
            }
            this.loader.stop();
          },
          (err) => {
            this.loader.stop();
          }
        );
    }
  }

  async uploadPhoto(event) {
   // console.log("event");
    this.fileToUpload = event.target.files[0];
    var fileExtension = '.' + this.fileToUpload.name.split('.')[1];
    this.uploadFileService.uploadFile(this.fileToUpload, 'common',fileExtension).then((a) => {
     // console.log(a);
      this.loader.stop();
      this.userProfileData.photo = this.uploadFileService.getPreSignedURL(a.Location.substr(
        a.Location.indexOf('.com/') + 5
      ));
    });
    this.showImageMenu = false;
  }

  cancel() {
    this.editProfile=!this.editProfile
    this.userProfileData.photo = this.tempPhoto;
    this.userProfileData.name = this.tempName
  }
}
