// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "http://localhost:3000/v1/",
  apiUrl: "https://www.dev.api.one.truventor.ai/v1/",
  engageUrl: "https://api.engage.truventor.ai/",
  referrerUrl: [
    {
      url: "https://dev.customer.truventor.ai/",
      portal: "customer"
    },
    {
      url: "https://portal.dev.partner.truventor.ai/", //partner-fe
      portal: "partner"
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
