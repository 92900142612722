import { Component, OnInit } from '@angular/core';
import { UtilsHelper } from '../../utils.helper';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    UtilsHelper.clearAll();
    UtilsHelper.setObject("logout", true);
    location.replace('/auth/login');
  }

}
