export class LoginModal{
  email: string;
  password: string;
}

export class ChangePasswordModal{
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
}

export class IPassValidArr {
  chrLength: boolean = false;
  oneCapital: boolean = false;
  oneSmall: boolean = false;
  oneNumber: boolean = false;
  oneSpecial: boolean = false;
}

export class UserProfile {
  _id: any;
  name: string;
  email: string;
  orgName: string;
  photo: string;
  userID: string;
}


export class Token {
  authToken: string
  exp: number
  iat: number
  isAdmin: boolean
  name: string
  orgID: string
  userID: string
  username: string
}


