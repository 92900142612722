import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class CommonService {

  profileImageUrl$ = new BehaviorSubject<string>("");
  profileName$ = new BehaviorSubject<string>("");

  private subject = new BehaviorSubject<string>("");
  public hidePopups = this.subject.asObservable();

  constructor() { }

  envUrl() {
    return environment.apiUrl;
  }

  engageUrl() {
    return environment.engageUrl;
  }

  hideOtherPopups(component_name) {
    this.subject.next(component_name);
  }


}
