import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';
import { ToastDisplay } from '../toastr/toast-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  ACCESS_KEY_ID: string = 'AKIAVFVN2XDKVUOKPMHP';
  SECRET_ACCESS_KEY: string = '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';
  REGION: string = 'ap-south-1';
  BUCKET_NAME: string = 'engage-storage';
  uploadURL = new Subject<string>();
  returnURL: string;
  fileExtentions = [
    'mp3',
    'mp4',
    '3gp',
    'mkv',
    'pdf',
    'doc',
    'docx',
    'ogg',
    'amr',
    'mpeg',
    'jpeg',
    'jpg',
    'png',
    'acc',
    'webp',
    'audio',
    'xlsx',
    'xls',
    'dxf',
    'step',
  ];

  constructor(
    private toastr: ToastDisplay,
    private loader: NgxUiLoaderService,
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  getFormattedTimestamp = () => {
    let date = new Date();
    let d = date.getDate();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let h = date.getHours();
    let min = date.getMinutes();
    let s = date.getSeconds();
    let ms = date.getMilliseconds();
    return y + d + m + h + min + s + ms;
  };

  uploadBase64(url: string, folder: string, type) {
    (window as any).global = window;
    // @ts-ignore
    window.Buffer = window.Buffer || require('buffer').Buffer;

    const buf = new Buffer(
      url.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );

    const bucket = new S3({
      accessKeyId: this.ACCESS_KEY_ID,
      secretAccessKey: this.SECRET_ACCESS_KEY,
      region: this.REGION,
    });
    const params = {
      Bucket: this.BUCKET_NAME,
      Key: folder + '/' + 'attachment' + this.getFormattedTimestamp() + type,
      Body: buf,
      ContentEncoding: 'base64',
      ContentType: 'image/jpeg',
    };

    this.loader.start();
    return bucket
      .upload(params, function (err, data, d = this.uploadURL) {
        if (err) {
          //  console.log('There was an error uploading your file: ', err);
          // return false;
        }
        //  console.log('Successfully uploaded file.', data);
        this.loader.stop();
        // return true;
      })
      .promise();
  }

  async uploadFile(file, folder, type, errorFileName?): Promise<any> {
    // var Obj = this; //this of angular comopnent

    if (
      this.calculateSize(file.name, file.size) &&
      this.isFileSupported(file.name)
    ) {
      let contentType = 'application/octet-stream';
      const bucket = new S3({
        accessKeyId: this.ACCESS_KEY_ID,
        secretAccessKey: this.SECRET_ACCESS_KEY,
        region: this.REGION,
      });
      const params = {
        Bucket: this.BUCKET_NAME,
        Key: folder + '/' + 'attachment' + this.getFormattedTimestamp() + type,
        Body: file,
        ContentType: contentType,
      };
      this.loader.start();
      return bucket
        .upload(params, function (err, data, d = this.uploadURL) {
          if (err) {
            //  console.log('There was an error uploading your file: ', err);
            // return false;
          }
          //  console.log('Successfully uploaded file.', data);
          this.loader.stop();
          // return true;
        })
        .promise();
    } else {
      if (!this.isFileSupported(file.name)) {
        if (!file.name) {
          this.toastr.showInformation('File is not supported!');
        }else{
          this.toastr.showInformation('File: ' + (errorFileName? errorFileName : file.name) + ' is not supported!');
        }
        return 'size-error';
      }
      this.toastr.showError('Size exceeded for file: ' + (errorFileName? errorFileName : file.name));
      return 'size-error';
    }

    //for upload progress
    // bucket.upload(params).on('httpUploadProgress', function (evt) {
    //       console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
    //   }).send(function (err, data) {
    //       if (err) {
    //           console.log('There was an error uploading your file: ', err);
    //           return false;
    //       }
    //       console.log('Successfully uploaded file.', data);
    //       return true;
    //   });
  }

  getPreSignedURL(fileName): string {
    var credentials = {
      accessKeyId: this.ACCESS_KEY_ID,
      secretAccessKey: this.SECRET_ACCESS_KEY,
    };
    AWS.config.update({ credentials: credentials, region: this.REGION });
    var s3 = new S3();

    var presignedGETURL = s3.getSignedUrl('getObject', {
      Bucket: this.BUCKET_NAME,
      Key: fileName, //filename
      Expires: 85000, //time to expire in seconds
    });

    return presignedGETURL;
  }

  calculateSize(fileType, fileZize, isProfile?): boolean {
    //byte to mb..
    let size = fileZize * 0.000001;

    if(isProfile){
      if (
        size > 5 &&
        (fileType.toString().toLowerCase().includes('image') || fileType.toString().toLowerCase().includes('img') )
      ) {
        return false;
      }
    }else{
    if (
      size > 50 &&
      (fileType.toString().toLowerCase().includes('pdf') ||
        fileType.toString().toLowerCase().includes('docx') ||
        fileType.toString().toLowerCase().includes('doc') ||
        fileType.toString().toLowerCase().includes('amr') ||
        fileType.toString().toLowerCase().includes('mpeg') ||
        fileType.toString().toLowerCase().includes('ogg') ||
        fileType.toString().toLowerCase().includes('xlsx') ||
        fileType.toString().toLowerCase().includes('xls') ||
        fileType.toString().toLowerCase().includes('acc'))
    ) {
      return false;
    } else if (
      size > 300 &&
      (fileType.toString().toLowerCase().includes('mp4') ||
        fileType.toString().toLowerCase().includes('3gpp') ||
        fileType.toString().toLowerCase().includes('mkv') ||
        fileType.toString().toLowerCase().includes('dxf') ||
        fileType.toString().toLowerCase().includes('step'))
    ) {
      return false;
    } else if (
      size > 30 &&
      (fileType.toString().toLowerCase().includes('webp') ||
        fileType.toString().toLowerCase().includes('png') ||
        fileType.toString().toLowerCase().includes('jpeg'))
    ) {
      return false;
    }
  }
    return true;

  }

  isFileSupported(fileName, isProfile?) {
    if(fileName){
      if(isProfile){
        if(fileName.toString().toLowerCase().includes('image') || fileName.toString().toLowerCase().includes('img') ){
          return true;
        }
        else{
          return false;
        }
      }else{
      return (
        this.fileExtentions.findIndex((f) =>
          fileName.toString().toLowerCase().includes(f)
        ) > -1
      );
      }
    }
    return false;
  }

  storeAttachment(chatID, payload) {
    return this.httpClient.post(
      this.commonService.envUrl() + `bot/attachment?chatID=${chatID}`,
      payload
    );
  }



  //only for user profile image
  async uploadFileProfile(file, folder, type): Promise<any> {
    // var Obj = this; //this of angular comopnent

    if (
      this.calculateSize(file.type, file.size, true) &&
      this.isFileSupported(file.type, true)
    ) {

      let contentType = "application/octet-stream";
      const bucket = new S3({
        accessKeyId: this.ACCESS_KEY_ID,
        secretAccessKey: this.SECRET_ACCESS_KEY,
        region: this.REGION,
      });
      const params = {
        Bucket: this.BUCKET_NAME,
        Key: folder + '/' + 'attachment'+this.getFormattedTimestamp()+type,
        Body: file,
        ContentType: contentType,
      };
      this.loader.start();
      return bucket
        .upload(params, function (err, data, d = this.uploadURL) {
          if (err) {
          //  console.log('There was an error uploading your file: ', err);
            // return false;
          }
        //  console.log('Successfully uploaded file.', data);
          this.loader.stop();
          // return true;
        })
        .promise();
    } else {
      if (!this.isFileSupported(file.type, true)) {
        if(!file.name){
          this.toastr.showInformation('File is not supported!');
        }else{
          this.toastr.showInformation('File: ' + file.name + ' is not supported!');
        }
        return 'size-error';
      }
      this.toastr.showError('Size exceeded for file: ' + file.name);
      return 'size-error';
    }
  }
}
